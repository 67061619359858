<template>
  <main class="page-activation-landing container">
    <div v-if="ok !== null" class="row h-100 justify-content-center">
      <div class="col align-self-center">
        <AwlModalCard :title="ok ? 'Congratulazioni!' : 'Ops!'">
          <div class="row">
            <div class="col-12">
              <p class="h4">
                {{ ok ? "Il tuo account è stato attivato con successo" : "Non è stato possibile attivare questo account" }}
              </p>
            </div>
          </div>
          <div class="pt-5">
            <router-link class="btn btn-primary" v-if="!$oauth2.me" :to="{ name: 'login' }">Accedi</router-link>
          </div>
        </AwlModalCard>
      </div>
    </div>
    <div v-else></div>
  </main>
</template>

<script>

import $api from '@/libs/Api/services/api.js';
import AwlModalCard from '@/views/components/Cards/AwlModalCard.vue';

export default {
  beforeRouteEnter: async (to, _, next) => {
    try {
      await $api.activateUser(to.params.email, to.params.token);

      return next(vm => {
        vm.ok = true;
      });
    } catch (error) {
      return next(vm => {
        vm.ok = false;
      });
    }
  },
  components: {
    AwlModalCard,
  },
  data: () => ({ ok: null }),
};

</script>

<style lang="scss">

.page-activation-landing {
  .card {
    margin: 0 auto;
    text-align: center;
    padding: 64px 32px;
    max-width: 700px;
  }
}

</style>
